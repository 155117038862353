//import css into react file

import React from 'react';
// import ExternalHomeScripts from './ExternalHomeScripts';
import { Link } from 'react-router-dom';





const Home = () => {







    return (
        <div>
              {/* <ExternalHomeScripts /> */}
          <>
  {/*  This site was created in Webflow. https://www.webflow.com  */}
  {/*  Last Published: Tue Jun 27 2023 20:45:03 GMT+0000 (Coordinated Universal Time)  */}
  <meta charSet="utf-8" />
  <title>NewStartUkraine</title>
  <meta
    content="https://uploads-ssl.webflow.com/5d6ed1bf3f524c7a6337af5d/5d6f1ee11698646c45faa311_Multiple%20Angled%20Mobile%20%2B%20Tablet%20Screens%20(24).png"
    property="og:image"
  />
  <meta
    content="https://uploads-ssl.webflow.com/5d6ed1bf3f524c7a6337af5d/5d6f1ee11698646c45faa311_Multiple%20Angled%20Mobile%20%2B%20Tablet%20Screens%20(24).png"
    property="twitter:image"
  />
  <meta content="width=device-width, initial-scale=1" name="viewport" />
  <meta content="Webflow" name="generator" />
  {/* <link href="css/normalize.css" rel="stylesheet" type="text/css" />
  <link href="css/webflow.css" rel="stylesheet" type="text/css" />
  <link
    href="css/refugeehubbuild.webflow.css"
    rel="stylesheet"
    type="text/css"
  /> */}
  <link href="https://fonts.googleapis.com" rel="preconnect" />
  <link
    href="https://fonts.gstatic.com"
    rel="preconnect"
    crossOrigin="anonymous"
  />
  <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
  <link href="images/webclip.svg" rel="apple-touch-icon" />
  <div className="w-embed">
    <style
      dangerouslySetInnerHTML={{
        __html:
          "\n::-moz-selection {\n\t\tbackground: #fece05;\n}\n::selection {\n\t\tbackground: #fece05;\n}\n"
      }}
    />
  </div>
  <div className="weird"> 
    <div className="hero_home-2 wf-section fadeIn">
      <div className="hero_content-2">
        <h3 id="blotter" className="topheading-2">
          Welcome to NewStartUkraine
        </h3>
        <h1 id="blotter" className="h_largeheading-2">
          Your Resource Hub for Refugees.
        </h1>
        <p className="paragraph-19 cc-large cc-w90">
    Empowering Refugees with Visa Assistance, Legal Support, Job Opportunities, and Cultural Integration. <span style={{ fontWeight: 600 }}>Start Below:</span>
  </p>



        <Link to="/wa" className="button-4 w-button">
          Ukraine -&gt; WA{" "}
        </Link>
      </div>
      <div className="hero_photo-3" />
    </div>
  </div>
  <div className="content-section wf-section">
    <div className="w-container">
      <div className="w-row">
        <div className="article-body-column w-col w-col-8">
          <div className="collection-list-wrapper-2 w-dyn-list">
            <div role="list" className="w-dyn-items">
              <div role="listitem" className="w-dyn-item" />
            </div>
            <div className="w-dyn-empty">
              <div>No items found.</div>
            </div>
          </div>
          <p className="paragraph-4" style={{ fontFamily: 'Times New Roman' }}>
  I started NewStartUkraine to help my global Ukrainian community by allowing refugees to feel welcome in my home, Washington State.
</p>

          <div className="images">
            <img src="images/ukraine-photo.png" alt="" className="big-image" />
          </div>
          <div className="rich-text-block-2 w-richtext" />
          <p className="paragraph-4" style={{ fontFamily: 'Times New Roman' }}>
  {" "}
  As a Ukrainian-American teenager, I unfortunately have never been to Ukraine, but I turned towards my passions of technology to explore my identity in times of crisis, since I can’t visit my ancestor’s roots.
  <br />
  <br />
  I used ReactJS, CSS, NodeJS, WordPress API CMS, PHP, MySQL. 
</p>

        </div>
        <div className="author-column w-col w-col-4">
          <div className="text-block-20" style={{ fontFamily: 'Times New Roman' }} >Learn More</div>
          <a
            href="https://davidbalin.com"
            className="link-block-19 w-inline-block"
          >
            <div className="text-block-19" style={{ fontFamily: 'Times New Roman' }}>
  Get in touch <span className="link-it-2 gdj">here</span>.
</div>

          </a>
          <div className="author-social-links">
            <img
              src="images/pfp ai.png"
              width={100}
              height={100}
              sizes="100px"
              srcSet="images/pfp ai.png 500w, images/pfp ai.png 618w"
              alt=""
              className="image-55"
            />
            <div className="author-connect" style={{ fontFamily: 'Times New Roman' }}>David Balin</div>
            <a
              href=""
              target="_blank"
              className="social-link w-inline-block"
            >
              <img
                src="https://uploads-ssl.webflow.com/5c12ebacd3261a0e4fc30785/5c12ebacd3261a092ec3079f_twitter-icon_black.svg"
                alt=""
              />
            </a>
            <a
              href=""
              target="_blank"
              className="social-link w-inline-block"
            >
              <img
                src="https://uploads-ssl.webflow.com/5c12ebacd3261a0e4fc30785/5c12ebacd3261a7eb5c30794_facebook-icon_black.svg"
                alt=""
              />
            </a>
            <a
              href="https://instagram.com/orangecattle"
              target="_blank"
              className="social-link last-link w-inline-block"
            >
              <img
                src="https://uploads-ssl.webflow.com/5c12ebacd3261a0e4fc30785/5c12ebacd3261aa998c30793_instagram-icon-black.svg"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="newsletter" className="subscribe-section wf-section">
    
  </div>
  
  <div
    data-collapse="all"
    data-animation="over-right"
    data-duration={400}
    id="Top"
    data-easing="ease"
    data-easing2="ease"
    role="banner"
    className="navbar navbar-3 w-nav"
  >
    <div className="div-block-22">
      <div data-hover="false" data-delay={0} className="w-dropdown">
        <div className="w-dropdown-toggle">
          <div className="w-icon-dropdown-toggle" />
          <div className="text-block-22">EN</div>
        </div>
        <nav className="w-dropdown-list">
          <a href="#" className="dropdown-link-3 linkoo w-dropdown-link">
            Рус
          </a>
          <a href="#" className="dropdown-link-4 linkoo w-dropdown-link">
            Укр
          </a>
        </nav>
      </div>
    </div>
    <nav role="navigation" className="nav-menu-12 w-nav-menu">
      <img
        src="images/Ralphie.png"
        width={100}
        sizes="100vw"
        srcSet="images/Ralphie-p-500.png 500w, images/Ralphie.png 618w"
        alt=""
        className="image-7"
      />
      <a href="#newsletter" className="nav-link-19 w-nav-link">
        newsletter
      </a>
      <a
        href="#"
        className="nav-link-19 w-hidden-main w-hidden-medium w-hidden-small w-hidden-tiny w-nav-link"
      >
        Layout 2
      </a>
      <a href="#read-more" className="nav-link-19 w-nav-link">
        read more
      </a>
      <a
        href="#"
        className="nav-link-19 w-hidden-main w-hidden-medium w-hidden-small w-hidden-tiny w-nav-link"
      >
        Portfolio
      </a>
      <a
        data-ix="fade-in-on-click"
        href="#"
        className="nav-link-19 w-hidden-main w-hidden-medium w-hidden-small w-hidden-tiny w-nav-link"
      >
        Newsletter
      </a>
      <a
        href="#"
        className="nav-link-19 w-hidden-main w-hidden-medium w-hidden-small w-hidden-tiny w-nav-link"
      >
        Journal
      </a>
      <a
        href="https://orangecattle.com"
        target="_blank"
        className="nav-link-19 w-nav-link"
      >
        contact
      </a>
    </nav>
    <div className="div-block-11">
      <a
        href="index.html"
        aria-current="page"
        className="link-block-22 w-inline-block w--current"
      />
    </div>
    
  </div>
</>
  




        </div>
    )
}





export default Home;

